<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>注册微信/支付宝</span>
            </div>

            <!-- 开通微信收款-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <span class="font1">开通微信收款</span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-link
                            href="https://8haodi-systemc.oss-cn-beijing.aliyuncs.com/release/微信支付申请材料.xls"
                            class="font2"
                            >①微信支付材料</el-link
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击“微信支付材料”下载文档模板-打开后按文档要求填写所有内容-保存后发给八号地工作人员(如有疑问请与八号地客服联系)
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-link
                            href="https://8haodi-systemc.oss-cn-beijing.aliyuncs.com/release/微信支付后续流程.doc"
                            class="font2"
                            >②下载后续操作流程</el-link
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        八号地帮助门店提交资料成功后，腾讯微信会在1-5个工作日内完成审核，并通过短信，邮件向门店联系人通知审核结果。
                        审核通过后，后续操作需门店自己独立完成，才能完成支付开通； 点击“下载后续操作流程” -
                        打开后按文档指南依次完成操作 -
                        完成后支付开通后联系八号地工作人员进行授权(如有疑问请与八号地客服联系)
                    </el-col>
                </el-row>
            </el-card>

            <!--开通支付宝收款-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <span class="font1">开通支付宝收款</span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-link
                            href="https://8haodi-systemc.oss-cn-beijing.aliyuncs.com/release/支付宝支付申请流程.doc"
                            class="font2"
                            >①支付宝支付材料</el-link
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击“支付宝支付材料”下载文档模板 - 打开后按文档指南依次完成操作 -
                            完成后方可使用支付宝支付收款(如有疑问请与八号地客服联系)
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'RegisterWechatAlipayGuide',
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
